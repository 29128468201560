<!-- @format -->

<template>
	<layout-default-new>
		<div>
			<sub-navbar page="Dispatch" />
		</div>
		<section class="hero is-info margin-bottom-20">
			<div class="hero-body">
				<p class="title">Edit / View dispatch</p>
				<p class="subtitle">Please select the dispatch to preview</p>
			</div>
		</section>
		<div v-if="loading" style="font-weight: 900; color: red">...Loading</div>
		<div v-if="!loading">
			<div
				style="
					background-color: rgb(76, 92, 122);
					padding: 20px;
					font-weight: 900;
					margin-bottom: -10px;
				"
			>
				<div style="color: white; margin-bottom: 20px">
					Between the dates: &nbsp; {{ beginningDate }} &nbsp; and &nbsp;
					{{ endingDate }}
				</div>
				<div style="display: flex; justify-content: start; font-weight: 900">
					<b-button
						class="is-info margin-right-20"
						style="width: 250px"
						@click="toggleDays"
						>{{ buttonTag }}</b-button
					>
					<div
						class="content margin-right-10"
						style="color: white; margin-top: 10px"
					>
						<b>Selection:</b>
					</div>
					<div style="color: white">
						<b-radio
							v-model="radio"
							type="is-warning"
							name="name"
							native-value="Dispatch is draft and not invoiced"
							style="margin-top: 10px"
						>
							Draft
						</b-radio>
						<b-radio
							v-model="radio"
							type="is-warning"
							name="name"
							native-value="Dispatch is locked and not invoiced"
							style="margin-top: 10px"
						>
							Not invoiced
						</b-radio>
						<b-radio
							v-model="radio"
							type="is-warning"
							name="name"
							native-value="Dispatch is locked and invoiced"
							style="margin-top: 10px"
						>
							Invoiced
						</b-radio>
						<b-radio
							v-model="radio"
							name="name"
							type="is-warning"
							native-value="All"
							style="margin-top: 10px"
						>
							All
						</b-radio>
					</div>
				</div>
			</div>
			<div class="margin-bottom-30">
				<ve-table
					:rows="rowsList"
					:columns="columnsList"
					:onCellClick="onCellClick"
					:onRowDoubleClick="onRowDoubleClick"
					:sort-options="sortOptions"
				>
				</ve-table>
			</div>
			<div v-if="!!isModalVisible">
				<modal-dispatch-frame processType="view" />
			</div>
		</div>
	</layout-default-new>
</template>

<script>
import SubNavbar from '@/_srcv2/components/_shared/sub-navbar/SubNavbar'
import GetDispatches from '@/_srcv2/pages/dispatch/view-dispatch/GetDispatchesQuery.graphql'
import GetOrdersToDispatchToSameDispatchAddressQuery from '@/_srcv2/pages/dispatch/graphql/GetOrdersToDispatchToSameDispatchAddressQuery.gql'
import { reactive, onMounted, ref, watch, computed } from '@vue/composition-api'
import Store from '@/store'
import Router from '@/router'
import { useQuery } from '@vue/apollo-composable'
import ModalDispatchFrame from '@/_srcv2/pages/_reports/get-data-and-report/dispatch/ModalDispatchFrame.vue'
import useProcessReportStore from '@/_srcv2/pages/_reports/create-report/useProcessReportStore.js'
import useDispatchEditStore from '@/_srcv2/pages/dispatch/edit-dispatch/store/useDispatchEditStore.js'
import dateformat from 'dateformat'

export default {
	name: 'ViewDispatch',
	components: {
		SubNavbar,
		ModalDispatchFrame,
	},
	setup() {
		const sortOptions = {
			enabled: true,
			initialSortBy: { field: 'dispatchNumber', type: 'desc' },
		}
		const radio = ref('Dispatch is draft and not invoiced')
		const buttonType = ref('is-success')
		// ! -----------------------------------------------------------------
		const today = ref(new Date())
		const days = ref(92)
		const buttonTag = ref('Get without time limited')
		const p1 = (condition) => {
			return new Promise((resolve, reject) => {
				if (condition) {
					console.log('Condition is ', condition)
					if (days.value === 92) {
						days.value = 1500
					} else {
						days.value = 92
					}
					if (buttonTag.value === 'Get without time limited') {
						buttonTag.value = 'Get last three months'
					} else {
						buttonTag.value = 'Get without time limited'
					}
					if (buttonType.value === 'is-success') {
						buttonType.value = 'is-info'
					} else {
						buttonType.value = 'is-success'
					}
					resolve(true)
				} else {
					const error = new Error('Condition is false')
					reject(error)
				}
			}).catch((err) => console.log('Error: ', err))
		}

		const toggleDays = () => {
			console.log('toggle is fired')
			p1(true).then(() => setInitialValues(radio.value))
		}
		const beginningDate = computed(() => {
			const bd = new Date().setTime(
				today.value - days.value * 24 * 60 * 60 * 1000,
			)
			console.log('bd', bd)
			console.log('myDate', dateformat(bd, 'yyyy-mm-dd'))
			return dateformat(bd, 'yyyy-mm-dd')
		})
		const endingDate = computed(() => {
			return dateformat(today.value, 'yyyy-mm-dd')
		})
		// ! -----------------------------------------------------------------
		const filterArray = (searchString, array) => {
			const checkItem = (item) => {
				return JSON.stringify(item).includes(searchString)
			}
			switch (searchString) {
				case 'All':
					return array
				case '':
					return []
				default:
					return array.filter(checkItem)
			}
		}
		// ! ---------------------------------------------------------
		const {
			orders,
			dispatchExchangeRate,
			dispatchExchangeUnit,
			dispatchType,
			ourCompany,
			customer,
			dispatchAddress,
			selectedDispatchNumber,
			selectedDispatchDate,
			variablesQ2,
		} = useDispatchEditStore()
		// ! ---------------------------------------------------------
		const options = reactive({
			fetchPolicy: 'no-cache',
		})
		const { refetch, loading } = useQuery(
			GetDispatches,
			() => ({
				company_id: Store.getters.getUserCurrentCompany,
				bd: beginningDate.value,
				ed: endingDate.value,
			}),
			options,
		)
		const columnsList = ref([
			{
				label: 'Select',
				field: 'select',
				width: '50px',
				sortable: false,
			},
			{
				label: 'Dispatch Date',
				field: 'date',
				width: '120px',
			},
			{
				label: 'Dispatch Number',
				field: 'dispatchNumber',
				width: '110px',
			},
			{
				label: 'Customer ID',
				field: 'id',
				width: '135px',
			},
			{
				label: 'Customer Title',
				field: 'title',
			},
			{
				label: 'Customer Nickname',
				field: 'nickname',
			},
			{
				label: 'Dispatch Status',
				field: 'status',
			},
		])
		// ! -------------------------------------------------------------
		const rawRowsList = ref([])
		const rowsList = ref([])
		const setInitialValues = (term) => {
			refetch()
				.then((result) => {
					console.log('result', result.data.dispatch_headers)
					rawRowsList.value = result.data.dispatch_headers.map((item) => {
						const note1 = item.dispatch_lock ? 'locked' : 'draft'
						const note2 = item.dispatch_invoiced ? 'invoiced' : 'not invoiced'
						let note = `Dispatch is ${note1} and ${note2}`
						return {
							date: item.dispatch_date,
							dispatchNumber: item.dispatch_number,
							id: item.customer.customer_id,
							title: item.customer.customer_title,
							nickname: item.customer.customer_nickname,
							status: note,
							ourCompany: item.company_id,
							isDispatchLocked: item.dispatch_lock,
							isDispatchInvoiced: item.dispatch_invoiced,
							reference: item.customer.customer_reference,
							email: item.customer.customer_email,
							orgNum: item.customer.customer_org_num,
							excRate: item.dispatch_exchange_rate,
							excUnit: item.dispatch_exchange_unit,
							documentType: 'dispatch',
							dispatchType: item.dispatch_type,
							dispatchAddressId: item.dispatch_address_id,
							invoiceAddressId: item.invoice_address_id,
						}
					})
				})
				.then(() => {
					rowsList.value = filterArray(term, rawRowsList.value)
				})
		}
		// ! ---------------------------------------------------------------------
		// * Define the variables for the Query  GetOrdersToDispatchToSameDispatchAddressQuery
		const optionsQ2 = reactive({
			fetchPolicy: 'no-cache',
		})
		const { refetch: refetchQ2, onResult: onResultQ2 } = useQuery(
			GetOrdersToDispatchToSameDispatchAddressQuery,
			() => variablesQ2,
			optionsQ2,
		)
		// ! -------------------------------------------------------------------------------
		const isConfirmationShown = ref(false)
		onMounted(() => {
			console.log('******** onMounted is fired')
			isConfirmationShown.value = false
		})
		const storeDataAndGetReport = (row) => {
			if (row.isDispatchLocked) {
				getPdfReport(row)
			} else {
				console.log('storeDataAndGetReport is fired orderNotLocked')
				// ! ---------------------------------------------------------------------------
				variablesQ2.company_id = row.ourCompany
				variablesQ2.customer_id = row.id
				variablesQ2.dispatch_address_id = row.dispatchAddressId
				variablesQ2.invoice_address_id = row.invoiceAddressId
				variablesQ2.order_exchange_rate = row.excRate
				variablesQ2.order_exchange_unit = row.excUnit
				variablesQ2.order_type = row.dispatchType
				console.log('variablesQ2', variablesQ2)
				// ! ----------------------------------------------------------------------------
				dispatchExchangeRate.value = row.order_exchange_rate
				dispatchExchangeUnit.value = row.order_exchange_unit
				dispatchType.value = row.order_type
				ourCompany.value = row.ourCompany
				customer.value = `${row.title} - ${row.nickname} - ${row.id}`
				selectedDispatchNumber.value = row.dispatchNumber
				selectedDispatchDate.value = row.dispatchDate
				// ! -------------------------------------------------------------------------------
				isConfirmationShown.value = true
				showConfirmation()
			}
		}
		onResultQ2((result) => {
			console.log('*** onResult is fired')
			if (isConfirmationShown.value === true) {
				console.log('onResultQ2 isConfirmationShown', isConfirmationShown.value)
				console.log('*-*-*-*-*- result.data', result.data)
				console.log('result.data.order_headers', result.data.order_headers)
				orders.value = result.data.order_headers
				dispatchAddress.value = result.data.addresses[0]
			} else {
				console.log('else is fired')
			}
		})
		// *** -----------------------------------------------------------
		const showConfirmation = () => {
			if (
				confirm(
					'Are you sure to edit the dispatch \n To cancel edit press "ok" \n To return back press "cancel"',
				)
			) {
				refetchQ2()
				Router.push({ name: 'EditDispatch' })
			} else {
				isConfirmationShown.value = false
			}
		}
		// ! ----------------------------------------------------------
		const {
			reportDocumentType,
			reportDocumentNumber,
			reportDocumentOurCompany,
			isSelectedDocumentLocked,
			isModalVisible,
			reportCustomer,
			reportDocumentDate,
			reportDocumentStatus,
			isSelectedDocumentReported,
			isUnlockButtonVisible,
		} = useProcessReportStore()
		const setVariables = (row) => {
			return new Promise((resolve, reject) => {
				if (row !== null || undefined) {
					reportDocumentType.value = row.documentType
					reportDocumentNumber.value = row.dispatchNumber
					reportDocumentOurCompany.value = row.ourCompany
					isSelectedDocumentLocked.value = row.isDispatchLocked
					isSelectedDocumentReported.value = row.isDispatchInvoiced
					isUnlockButtonVisible.value = !row.isDispatchInvoiced
					reportCustomer.value = `${row.id} - ${row.title} - ${row.nickname}`
					reportDocumentDate.value = row.date
					reportDocumentStatus.value = row.status
					resolve('ok')
				} else {
					const reason = new Error('Data could not be fetched from database')
					reject(reason)
				}
			})
		}
		// -----------------------------------------------------------------------------------
		const getPdfReport = (row) => {
			setVariables(row)
				.then(() => (isModalVisible.value = true))
				.then(() =>
					console.log(
						'isSelectedDocumentLocked.value: ',
						isSelectedDocumentLocked.value,
						'row: ',
						row,
					),
				)
		}
		// ---------------------------------------------------------------------------------------
		const onCellClick = (params) => {
			if (params.column.field === 'select') {
				console.log(params.row)
				storeDataAndGetReport(params.row)
			}
		}
		const onRowDoubleClick = (params) => {
			storeDataAndGetReport(params.row)
		}
		// ! ------------------------------------------------------------------------------
		const computedKey = computed(() => Store.getters.getKeyTable)
		watch(
			() => computedKey.value,
			() => {
				setInitialValues(radio.value)
			},
		)
		watch(
			() => radio.value,
			() => {
				setInitialValues(radio.value)
			},
		)
		return {
			sortOptions,
			loading,
			columnsList,
			rowsList,
			onCellClick,
			onRowDoubleClick,
			isModalVisible,
			radio,
			beginningDate,
			endingDate,
			toggleDays,
			buttonTag,
			buttonType,
			isConfirmationShown,
		}
	},
}
</script>

<style scoped>
.modal-backdrop {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: rgba(0, 0, 0, 0.9);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 100000000001;
}
.modal {
	background: #ffffff;
	box-shadow: 2px 2px 20px 1px;
	overflow-x: auto;
	display: flex;
	flex-direction: column;
	max-width: 1100px;
	max-height: 100%;
	height: 80%;
	margin: auto;
	padding: 20px;
	opacity: 100%;
}
.modal-header,
.modal-footer {
	padding: 15px;
	display: flex;
}
.modal-header {
	position: relative;
	color: #4aae9b;
	justify-content: space-between;
}
.modal-footer {
	flex-direction: column;
	justify-content: flex-end;
	margin-bottom: 30px;
}
.modal-body {
	position: relative;
	padding: 20px 10px;
}
.slide-fade-enter-active {
	transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
	transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
	transform: translateX(20px);
	opacity: 0;
}
</style>
